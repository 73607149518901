import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Tag } from '@class101/ui';
import { PlayGroundContainer, ThemeProvider } from '../docsComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tag"
    }}>{`Tag`}</h1>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Tag} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<PlayGroundContainer>\n  <Tag value=\"배송 중\" />\n  <Tag label=\"삭제 됨\" value=\"archived\" />\n  <Tag value=\"내 담당\" />\n</PlayGroundContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Tag,
      PlayGroundContainer,
      ThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundContainer mdxType="PlayGroundContainer">
    <Tag value="배송 중" mdxType="Tag" />
    <Tag label="삭제 됨" value="archived" mdxType="Tag" />
    <Tag value="내 담당" mdxType="Tag" />
  </PlayGroundContainer>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      